import request from '@/service/lib/request';
// 角色管理

/**
 * 角色管理 - 分页
 * @author lb
 */
export function queryList(data) {
    return request({
      url: '/role/get_role_page',
      method: 'post',
      data
    });
}

/**
 * 角色管理 - 新增
 * @author lb
 */
export function add(data) {
  return request({
    url: '/role/add_role',
    method: 'post',
    data
  });
}

/**
 * 角色管理 - 修改
 * @author lb
 */
export function update(data) {
  return request({
    url: '/role/update_role',
    method: 'post',
    data
  });
}

/**
 * 角色管理 - 删除
 * @author lb
 */
export function deleteBatch(data) {
  return request({
    url: '/role/delete_role',
    method: 'post',
    data
  });
}

/**
 * 角色管理 - 批量添加权限
 * @author lb
 */
export function batchUpdateRight(data) {
  return request({
    url: '/role/batch_update_right',
    method: 'post',
    data
  });
}
