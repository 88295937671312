<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
                <el-button type="primary" icon="el-icon-plus">批量删除</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键">
            </el-table-column>
            <el-table-column
                prop="code"
                label="角色代码">
            </el-table-column>
            <el-table-column
                prop="name"
                label="角色名称">
            </el-table-column>
            <el-table-column
                prop="createUserId"
                label="创建人">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text">查看</el-button>
                    <el-dropdown class="margin-l5">
                        <span class="cursor-pointer">
                            <el-button type="text" >更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="tableEdit(scope.row)">编辑</el-dropdown-item>
                            <el-dropdown-item @click.native="tableDel(scope.row)">删除</el-dropdown-item>
                            <el-dropdown-item @click.native="tableAddRight(scope.row)">添加权限</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='500px' :before-close='cancel'>
        <el-form ref='form' :model='form' :rules='rules' label-width='130px'>
            <el-form-item label='角色代码' prop='code'>
                <el-input v-model='form.code' placeholder='请输入角色代码' />
            </el-form-item>
            <el-form-item label='角色名称' prop='name'>
                <el-input v-model='form.name' placeholder='请输入角色名称' />
            </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
    
    <el-dialog title="添加权限" :visible.sync='rightOpen' width='500px' :before-close='cancel'>
        <el-form ref='rightForm' :model='rightForm' :rules='rules' label-width='130px'>
            <el-form-item label='角色代码' prop='code'>
                <el-input v-model='rightForm.code' placeholder='请输入角色代码' disabled/>
            </el-form-item>
            <el-form-item label='角色名称' prop='name'>
                <el-input v-model='rightForm.name' placeholder='请输入角色名称' disabled/>
            </el-form-item>
            <el-form-item label='用户ID' prop='userIds'>
                <el-select v-model="rightForm.userIds" multiple placeholder="请选择">
                  <el-option
                    v-for="item in userIds"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitFormRight'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { queryList, add, update, deleteBatch, batchUpdateRight } from '@/service/role';
import { queryAll } from '@/service/user';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        code: [
          { required: true, message: '角色代码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
      },
      rightForm:{
        roleId: null,
        userIds: null
      },
      rightOpen: false,
      userIds: []
    };
  },
  created() {
    this.getList();
    queryAll().then(response => {
      this.userIds = response['data'];
    });
  },
  methods: {
    getList() {
      queryList(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {};
    },
    tableEdit(row){
        this.title = '修改';
        this.open = true;
        this.form = {...row};
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
      this.rightOpen = false;
      this.resetForm('rightOpen');
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.id !== undefined) {
            update(this.form).then(response => {
              if (response.code === 0) {
                this.cancel();
                this.getList();
              } else {
                this.$message({
                    type: 'info',
                    message: '异常'
                });  
              }
            });
          } else {
            add(this.form).then(response => {
              if (response.code === 0) {
                this.cancel();
                this.getList();
              } else {
                this.$message({
                    type: 'info',
                    message: '异常'
                });  
              }
            });
          }
        }
      });
    },
    tableDel(row){
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              console.log(row);
              deleteBatch({ids: [row.id]}).then(response => {
                if (response.code === 0) {
                  this.$message({
                      type: 'success',
                      message: '删除成功!'
                  });
                  this.getList();
                } else {
                  this.$message({
                      type: 'info',
                      message: '异常'
                  });  
                }
              });
          }).catch(() => {
              this.$message({
                  type: 'info',
                  message: '已取消删除'
              });          
          });
    },
    tableAddRight(row){
      this.rightForm = {
        roleId: row.id,
        name: row.name,
        code: row.code
      };
      this.rightOpen = true;
    },
    submitFormRight(){
      batchUpdateRight(this.rightForm).then(response => {
        if (response.code === 0) {
          this.cancel();
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    }
  }
};
</script>